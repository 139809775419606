import styled, { css } from 'styled-components';

const SolutionWrapper = styled.div`
  padding-top: 5rem;
  padding-bottom: 2rem;
  .solution-meta {
    padding-top: 5rem;
    text-align: center;
    font-size: 0.85rem;
    line-height: 25px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.labelColor};
    margin-bottom: 2rem;
    margin: 0 5rem;
  }
  .solution-tags {
    padding-top: 5rem;
    text-align: center;
    font-size: 0.95rem;
    line-height: 25px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.labelColor};
    margin-bottom: 2rem;
    margin: 0 5rem;
    span {
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      text-transform: uppercase;
      &.divider {
        margin: 0 6px;
      }
      &.secondary {
        color: ${({ theme }) => theme.colors.orange};
      }
    }
  }
  .solution-info {
    text-align: center;
    margin: 0 7rem;
    margin-bottom: 2rem;
    font-size: 38px;
    line-height: 72px;
    font-weight: 700;
    @media only screen and (max-width: 990px) {
      margin: 0 5rem;
    }
    @media only screen and (max-width: 768px) {
      font-size: 30px;
      line-height: 62px;
      margin: 0 2rem;
    }
    @media only screen and (max-width: 480px) {
      font-size: 24px;
      line-height: 50px;
      margin: 0 0.5rem;
    }
  }
  .solution-cover {
    justify-content: center;
    display: flex;
  }
  .solution-body {
    margin: 0 10rem;
    @media only screen and (max-width: 990px) {
      margin: 0 5rem;
    }
    @media only screen and (max-width: 768px) {
      margin: 0 3rem;
    }
    @media only screen and (max-width: 480px) {
      margin: 0 0.5rem;
    }
    img {
      max-width: 100%;
    }
    h1,
    h2,
    h3,
    h4 {
      padding-top: 16px;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.headingColor};
      font-weight: 500;
    }
    p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.textColor};
      @media only screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    ul,
    ol {
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.textColor};
      @media only screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 24px;
      }
      li {
        list-style-type: circle;
        a {
          font-weight: 400;
          color: ${({ theme }) => theme.colors.headingColor};
        }
      }
    }
    ol {
      li {
        list-style-type: decimal;
      }
    }
    .text-center {
      text-align: center;
    }
    .pt-40 {
      padding-top: 2.5rem;
    }
    .pb-30 {
      padding-bottom: 2rem;
    }
    .font-weight-900 {
      font-weight: 900;
    }
    blockquote {
      color: ${({ theme }) => theme.colors.black};
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      margin: 0;
      margin-bottom: 30px;
      position: relative;
      z-index: 1;
      @media only screen and (max-width: 480px) {
        font-size: 24px;
        line-height: 36px;
      }
      .quotations {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 5rem;
        color: ${({ theme }) => theme.colors.headingColor};
        opacity: 0.5;
        z-index: -1;
      }
    }
    hr {
      width: 75%;
    }
  }
`;

export const SolutionBanner = styled.section`
  position: relative;
  box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
  margin-top: 100px;
  padding-top: 50px;
  min-height: 65vh;
  background-color: ${({ theme }) => theme.colors.blue};
  ${({ cover }) =>
    cover &&
    css`
      background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
        url(${cover});
    `};

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  ${'' /*
  @media (min-width: 1440px) {
    background-size: 130vh;
  }
  @media (max-width: 1440px) {
    background-size: 125vh;
  }
  @media (max-width: 1200px) {
    background-size: 115vh;
  }
 */}
  @media (max-width: 991px) {
    margin-top: 99px;
  }
  @media (max-width: 768px) {
    ${'' /* background-image: none; */}
  }
  @media (max-width: 572px) {
    margin-top: 92px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    align-items: center;
  }
  .col {
    padding-right: 15px;
    padding-left: 15px;
    width: 70%;
    @media (max-width: 991px) {
      width: 65%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 572px) {
      width: 100%;
    }
  }

  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        margin-right: 30px;
        @media (max-width: 990px) {
          margin-right: 0;
        }
        ${'' /* &:hover {
          box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow}
        } */}
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .btn-text {
        padding-left: 15px;
        font-size: 15px;
        font-weight: 500 !important;
        font-family: 'Poppins', sans-serif;
      }
      &:hover {
        box-shadow: none !important;
      }
    }
  }
  .feature__block {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 500;
      color: #fff;
      letter-spacing: -0.025em;
      line-height: 1.15;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    h1 {
      font-size: 3.25rem;
      @media (max-width: 1440px) {
        font-size: 3.25rem;
      }
      @media (max-width: 990px) {
        font-size: 3rem;
      }
      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
      @media (max-width: 572px) {
        font-size: 2.25rem;
      }
    }
    p {
      color: #fff;
      line-height: 30px;
      margin-bottom: 20px;
      max-width: 550px;
      font-size: 22px;
      @media (max-width: 990px) {
        font-size: 20px;
      }
      @media (max-width: 572px) {
        font-size: 18px;
      }
    }
  }
`;

export const SolutionBox = styled.div`
  box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.lightBlue};
  position: absolute;
  bottom: -65px;
  right: 15%;
  left: 15%;
  min-height: 125px;
  padding: 1.5rem 1.85rem;
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    right: 10%;
    left: 10%;
  }
  @media (max-width: 768px) {
    right: 5%;
    left: 5%;
  }
  @media (max-width: 572px) {
    right: 0;
    left: 0;
    bottom: -100px;
  }
  h2,
  h3,
  h4 {
    font-weight: 700;
    color: #fff;
    letter-spacing: -0.015em;
    line-height: 1.15;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  h3,
  h4 {
    font-size: 2rem;
    @media (max-width: 1440px) {
      font-size: 2rem;
    }
    @media (max-width: 990px) {
      font-size: 1.5rem;
    }
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
    @media (max-width: 572px) {
      font-size: 1.15rem;
    }
  }
  p {
    color: #fff;
    line-height: 26px;
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 990px) {
      font-size: 16px;
    }
    @media (max-width: 572px) {
      font-size: 14px;
    }
  }
  .info-box {
    .info-box-details {
      color: #fff;
      margin-top: 0.5rem;
      font-size: 0.85rem;
    }
  }
  .info-box-btn {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    text-transform: none;
    @media (max-width: 768px) {
      justify-content: center;
    }
    button {
      text-transform: none;
      transition: 0.2s all;
      &:hover {
        background: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.textColor};
      }
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  ${({ template }) => {
    if (template === undefined) {
      return css`
        grid-auto-flow: row;
        grid-template-areas: 'a a';
      `;
    }
    if (typeof template === 'string') {
      return css`
        grid-template-columns: ${template};
      `;
    }
    return css`
      grid-template-columns: ${template[0]};
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-template-columns: ${template[1]};
        grid-template-rows: ${template[1]};
      }
    `;
  }}}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

export default SolutionWrapper;
