import React from 'react';
import { Link } from 'gatsby';
import Container from 'common/source/components/UI/Container';
import Button from 'reusecore/src/elements/Button';
import CategoryGridWrapper, { GridContainer, CategoryWrapper } from './categorygrid.style';
import FeaturedCategory from './FeaturedCategory';

const CategoryList = ({ heading, subtitle, categories }) => {
  if (categories.length < 1) {
    return null;
  }
  if (categories.length === 1) {
    return <FeaturedCategory category={categories[0]} heading={heading} subtitle={subtitle} />;
  }
  return (
    <CategoryGridWrapper>
      <Container>
        <GridContainer template="auto">
          <div className="inner-heading">
            <h2>{heading}</h2>
            <p>{subtitle}</p>
          </div>
        </GridContainer>
        <GridContainer>
          {categories.map(category => {
            const {
              frontmatter: { slug, cover, title, description, prefix }
            } = category;
            const path = prefix ? `${prefix}/${slug}` : `/${slug}`;
            return (
              <CategoryWrapper height="70vh" cover={cover} key={`key${prefix}${slug}`}>
                <h3>{title}</h3>
                <p>{description}</p>
                <Link to={path}>
                  <Button title="Más de estas soluciones" variant="outlined" />
                </Link>
              </CategoryWrapper>
            );
          })}
        </GridContainer>
      </Container>
    </CategoryGridWrapper>
  );
};

export default CategoryList;
