import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import Navbar from 'common/source/containers/Navbar';
import SolutionView from 'common/source/containers/SolutionView';
import RelatedProducts from 'common/source/containers/RelatedProducts';
import RelatedCategories from 'common/source/containers/RelatedCategories';
import RelatedSolutionsList from 'common/source/containers/RelatedSolutionsList';
import CTABanner from 'common/source/containers/CTABanner';

import Footer from 'common/source/containers/Footer';

import SEO from '../components/SEO';

class SolutionTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug, prevPost, nextPost } = pageContext;
    const { post, products, categories } = data;
    const moreProducts = products.edges.map(edge => edge.node);
    // const solutionCatalogs = catalog.edges.map(edge => edge.node);
    const solutionCategories = categories.edges.map(edge => edge.node);
    const { frontmatter } = post;
    if (!frontmatter.id) {
      frontmatter.id = slug;
    }
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={post} postSEO prefix="soluciones" />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar background="purple" />
              </DrawerProvider>
            </Sticky>
            <SolutionView post={post} />
            <RelatedProducts
              heading="Productos Sugeridos"
              subtitle="Algunos sugeridos productos para esta solución:"
              related={moreProducts}
            />
            <RelatedCategories
              heading="Categorías"
              // subtitle="Algunos sugeridos productos para esta solución:"
              categories={solutionCategories}
            />
            <RelatedSolutionsList
              prevPost={prevPost}
              nextPost={nextPost}
              prefix="soluciones"
              heading="Otras Soluciones"
              subtitle="Sigue leyendo otras soluciones relacionadas:"
            />
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default SolutionTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query SolutionBySlug(
    $slug: String!
    $category: [String]!
    $related: [String]!
    $products: [String]!
  ) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug }, type: { eq: "SOLUTION" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
      }
      frontmatter {
        slug
        title
        type
        cover
        thumbnail
        featured
        tags
        manual_pdf
      }
    }
    catalog: allMarkdownRemark(filter: { frontmatter: { slug: { in: $related } } }) {
      edges {
        node {
          html
          excerpt
          fields {
            slug
          }
          frontmatter {
            slug
            title
            description
            cover
            thumbnail
          }
        }
      }
    }
    categories: allMarkdownRemark(filter: { frontmatter: { slug: { in: $category } } }) {
      edges {
        node {
          html
          excerpt
          fields {
            slug
          }
          frontmatter {
            slug
            prefix
            title
            description
            cover
            thumbnail
          }
        }
      }
    }
    products: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "PRODUCT" }, sku: { in: $products }, published: { eq: true } }
      }
      sort: { order: ASC, fields: [frontmatter___featured] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            sku
            title
            slug
            description
            tags
            thumbnail
            cover
          }
        }
      }
    }
  }
`;
