import React, { Fragment } from 'react';
import Container from 'common/source/components/UI/Container';
// import Image from 'reusecore/src/elements/Image';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import { Icon } from 'react-icons-kit';
import { download } from 'react-icons-kit/entypo/download';
import SolutionWrapper, { SolutionBanner, GridContainer, SolutionBox } from './solution.style';
import FeatureBlock from '../../components/FeatureBlock';

const SolutionView = ({ post }) => {
  const {
    html,
    frontmatter: { title, cover, manual_pdf }
  } = post;
  return (
    <Fragment>
      <SolutionBanner cover={cover}>
        <Container>
          <Box className="row">
            <Box className="col">
              <FeatureBlock title={<Heading as="h1" content={title} />} />
            </Box>
          </Box>
        </Container>
        {manual_pdf && (
          <SolutionBox>
            <GridContainer template={['1fr 1fr', '1fr']}>
              <div className="info-box">
                <p>Puedes descargar esta solución aquí:</p>
                <div className="info-box-details">
                  <em>{title}</em>
                </div>
              </div>
              <div className="info-box-btn">
                <a href={manual_pdf} target="_blank" rel="noopener noreferrer">
                  <Button
                    title="Descarga el Brochure"
                    variant="outlined"
                    iconPosition="left"
                    icon={<Icon icon={download} />}
                  />
                </a>
              </div>
            </GridContainer>
          </SolutionBox>
        )}
      </SolutionBanner>
      <SolutionWrapper>
        <Container>
          <div className="solution-body" dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </SolutionWrapper>
    </Fragment>
  );
};

export default SolutionView;
