import React from 'react';
import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Container from '../../components/UI/Container';
import CtaBannerWrapper from './cta.style';

const CTABanner = () => {
  return (
    <CtaBannerWrapper>
      <Container>
        <Box>
          <Heading as="h3" content="Con dudas? Cuéntanos sobre tu proyecto!" />
        </Box>
        <Box>
          <Link to="/soluciones">
            <Button variant="hoverShadow" title="Ver más soluciones" />
          </Link>
          <Link to="/contacto">
            <Button
              className="contact-btn"
              variant="outlined"
              title="Ponte en contacto con el equipo de ventas"
            />
          </Link>
        </Box>
      </Container>
    </CtaBannerWrapper>
  );
};

export default CTABanner;
