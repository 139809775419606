import React from 'react';
import { Link } from 'gatsby';
import Container from 'common/source/components/UI/Container';
import Button from 'reusecore/src/elements/Button';
import CategoryGridWrapper, { GridContainer, CategoryWrapper } from './categorygrid.style';

const CategoryListFeatured = ({ heading, subtitle, category }) => {
  const {
    frontmatter: { slug, cover, title, description, prefix }
  } = category;
  const path = prefix ? `${prefix}/${slug}` : `/${slug}`;
  return (
    <CategoryGridWrapper>
      <Container>
        <GridContainer template="auto">
          <div className="inner-heading">
            <h2>{heading}</h2>
            <p>{subtitle}</p>
          </div>
        </GridContainer>
        <GridContainer template="auto" single>
          <CategoryWrapper height="70vh" cover={cover}>
            <h3>{title}</h3>
            <p>{description}</p>
            <Link to={path}>
              <Button title="Más de estas soluciones" variant="outlined" />
            </Link>
          </CategoryWrapper>
        </GridContainer>
      </Container>
    </CategoryGridWrapper>
  );
};

export default CategoryListFeatured;
